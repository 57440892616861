import React from "react";
import Modal from "react-modal";

const ViewDetailsBecomeSpeaker = ({ closeModalHandler, viewModalData }) => {
  const details = viewModalData?.detailJsonResponse;

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModalHandler}
      className="modalFormView"
      overlayClassName="modal-overlay-view"
      ariaHideApp={false}
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-full">
        {/* Close Button */}
        <div className="w-full flex justify-end">
          <button
            className="text-red-600 text-lg font-bold mb-4"
            onClick={closeModalHandler}
          >
            ✖
          </button>
        </div>

        {/* Header Section */}
        <div className="flex items-center space-x-4 border-b pb-4 mb-6">
          <img
            src={
              details?.user_profile_picture != "" &&
              details?.user_profile_picture
                ? details?.user_profile_picture
                : "/images/profile_pic.png"
            }
            alt={viewModalData?.name}
            className="w-24 h-24 rounded-full shadow-lg"
          />
          <div>
            {/* <h1 className="text-2xl font-bold">
              {details?.designation_name} {viewModalData?.name}
            </h1> */}

            <ul className="list-none mt-2">
              <li className="text-2xl font-bold mb-1">
                {details?.designation_name} {viewModalData?.name}
              </li>
              <li className="mb-1"><strong> Email:</strong> {viewModalData?.expertUserEmail}   <strong className="ml-10"> Phone:</strong> {details?.phone_number}</li>
              {/* <li></li> */}
              <li><strong>Digistore Id:</strong> {viewModalData?.expertUserDigistoreId}</li>
            </ul>

            {/* <p className="text-gray-600">{details?.expert_short_description}</p> */}
          </div>
        </div>

        {/* Additional Details Section */}
        <div>
          <h2 className="text-[16px] font-semibold mb-4">
            Additional Information
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="p-4 bg-gray-50 rounded-lg shadow">
              <h3 className="font-semibold text-[16px]">Description Details</h3>
              <ul className="list-none mt-2">
                {/* <li>Email: {viewModalData?.expertUserEmail}</li>
                <li>Phone: {details?.phone_number}</li> */}
                <li>
                  <strong>Short Description: </strong>
                  {details?.expert_short_description}
                </li>
                <li>
                  <strong>Long Description: </strong>{" "}
                  {details?.long_description}
                </li>
              </ul>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg shadow">
              <h2 className="font-semibold text-[16px]">Direct Links</h2>
              <ul className="list-none mt-2 space-y-2">
                {details?.home_page_term &&
                details?.home_page_link &&
                details?.newsletter_term &&
                details?.newsletter_link &&
                details?.freebie_term &&
                details?.freebie_link &&
                details?.contact_email_term &&
                details?.contact_email_address ? (
                  <>
                    <li>
                      {details?.home_page_term && (
                        <span className="font-semibold">
                          {details.home_page_term}:{" "}
                        </span>
                      )}
                      {details?.home_page_link && (
                        <a
                          href={
                            details.home_page_link.startsWith("http")
                              ? details.home_page_link
                              : `https://${details.home_page_link}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline focus:text-blue-500 focus:underline"
                        >
                          {details.home_page_link}
                        </a>
                      )}
                    </li>
                    <li>
                      {details?.newsletter_term && (
                        <span className="font-semibold">
                          {details.newsletter_term}:{" "}
                        </span>
                      )}
                      {details?.newsletter_link && (
                        <a
                          href={details.newsletter_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline focus:text-blue-500 focus:underline"
                        >
                          {details.newsletter_link}
                        </a>
                      )}
                    </li>
                    <li>
                      {details?.freebie_term && (
                        <span className="font-semibold">
                          {details.freebie_term}:{" "}
                        </span>
                      )}
                      {details?.freebie_link && (
                        <a
                          href={details.freebie_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline focus:text-blue-500 focus:underline"
                        >
                          {details.freebie_link}
                        </a>
                      )}
                    </li>
                    <li>
                      {details?.contact_email_term && (
                        <span className="font-semibold">
                          {details.contact_email_term}:{" "}
                        </span>
                      )}
                      {details?.contact_email_address && (
                        <a
                          href={details.contact_email_address}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline focus:text-blue-500 focus:underline"
                        >
                          {details.contact_email_address}
                        </a>
                      )}
                    </li>{" "}
                  </>
                ) : (
                  <li>No Data</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* Products Section */}
        <div className="mb-6">
          <h2 className="text-[16px] font-semibold mb-4">Products</h2>
          {details?.product_title &&
          Object.keys(details.product_title).length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {Object.entries(details?.product_title || {}).map(
                ([key, title], index) => (
                  <div
                    key={key}
                    className="flex items-center p-4 bg-gray-50 rounded-lg shadow"
                  >
                    <img
                      src={details?.product_featured_image[`p${index}`]}
                      alt={title}
                      className="w-32 h-32 rounded-lg mr-4"
                    />
                    <div>
                      <h3 className="font-semibold text-lg">{title}</h3>
                      <a
                        href={`https://${
                          details?.product_link[`product_link_${index}`]
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline focus:text-blue-500 focus:underline"
                      >
                        View Product
                      </a>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <p className="text-gray-500">No products available</p>
          )}
        </div>

        {/* Social Links Section */}
        <div className="mb-6">
          <h2 className="text-[16px] font-semibold mb-2">Social Links</h2>

          {Object.entries(details).filter(
            ([key, value]) =>
              key.includes("_link") &&
              !key.includes("link_") &&
              value &&
              value?.length > 0
          ).length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {Object.entries(details)
                .filter(
                  ([key, value]) =>
                    key.includes("_link") &&
                    !key.includes("link_") &&
                    value &&
                    value?.length > 0
                )
                .map(([key, value]) => (
                  <a
                    key={key}
                    href={`https://${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block p-3 bg-blue-50 text-blue-600 rounded-lg focus:text-blue-600 shadow hover:bg-blue-100"
                  >
                    {key.replace("_link", "").replace("_", " ").toUpperCase()}
                  </a>
                ))}
            </div>
          ) : (
            <p className="text-gray-500">No social links available</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewDetailsBecomeSpeaker;
