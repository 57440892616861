import axios from "axios";


export const getBecomeSpeakerList = (props) => {
    return axios({
        method: 'GET',
        url: '/expertRequest',
        params: {status:0}
    })
}

export const deleteBecomeSpeaker = (id) => {
    return axios({
        method: 'DELETE',
        url: '/deleteExpertRequest',
        data:{id}
        
    })
}

export const updateBecomeSpeaker = (props) => {
    return axios({
        method:  'PATCH',
        url: "/expertRequestStatusUpdate",
        data:props
    })
}

export const postBecomeSpeaker=(props)=>{
    
    return axios({
        method:'POST',
        url:"/makeExpertRequest",
        data:props
    })
}

export const postPhotoBecomeSpeaker=(props)=>{
    
    return axios({
        method:'POST',
        url:"/attachment/upload",
        data:props
    })
}
//Experts User

export const getExpertData = () => {
    return axios({
        method: 'GET',
        url: '/user/refUser',
    })
}


export const updateExpertData=(props)=>{
    
    return axios({
        method:'PATCH',
        url:"/user/refUser",
        data:props
    })
}
///Products


export const getExpertProductList = (props) => {
    return axios({
        method: 'GET',
        url: '/products',
    })
}

export const deleteExpertProduct = (props) => {
    return axios({
        method: 'DELETE',
        url: '/products',
        data:props
    })
}

export const updateExpertProduct = (props) => {
    return axios({
        method:  'PATCH',
        url: "/products",
        data:props
    })
}

export const postExpertProduct=(props)=>{
    
    return axios({
        method:'POST',
        url:"/products",
        data:props
    })
}


//General Settings


export const getExpertGeneralSettings = () => {
    return axios({
        method: 'GET',
        url: '/generalSettings',
    })
}

export const postExpertGeneralSettings = (data) => {
    return axios({
        method: 'POST',
        url: '/generalSettings',
        data:data
    })
}
