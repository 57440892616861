import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import "cropperjs/dist/cropper.css";
import { postPhotoBecomeSpeaker } from "../../../Components/action/becomeSpeakerAction";
import { BASE_URL } from "../../../Components/Common/constants";
import axios from "axios";

const CropProductImage = ({ errors,index, isSubmited,updateProductPicture }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cropperRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (img.width < 300 || img.height < 300) {
          setError("Image must be at least 300x300 pixels.");
        } else {
          setError("");
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setIsModalOpen(true);
          };
          reader.readAsDataURL(file);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop,
  });

  const handleCropConfirm = async () => {
    if (cropperRef.current?.cropper !== "undefined") {
      const croppedData = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const base64Data = croppedData.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
      }

      const blob = new Blob(byteArrays, { type: "image/png" });
      const file = new File([blob], "cropped-image.png", { type: "image/png" });

      const formData = new FormData();
      formData.append("file", file);

      try {
        // const response = await postPhotoBecomeSpeaker(formData);
        const response = await axios({
          method: 'POST',
          url: `${BASE_URL}/attachment/upload`,
          data: formData,
      });

        setCroppedImage(response?.data?.responseData?.filePath);
        setIsModalOpen(false);
        updateProductPicture(response?.data?.responseData?.filePath, index);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  // const handleCropConfirm = async () => {
  //   if (cropperRef.current?.cropper !== "undefined") {
  //     const croppedData = cropperRef.current?.cropper
  //       .getCroppedCanvas()
  //       .toDataURL();
  //     setCroppedImage(croppedData);
  //     setIsModalOpen(false);
  //     updateProductPicture(croppedData,index);

  //   }
  // };

  const handleCropCancel = () => {
    setIsModalOpen(false);
    setImage(null);
  };

  return (
    <div className=" ">
      <div className="w-full flex gap-10 xl:mt-10">
        <div {...getRootProps()} className="cursor-pointer">
          <input {...getInputProps()} />
          <span className="upload-btn">Product Image</span>
        </div>

        {croppedImage ? (
          <>
            <div className="w-[50px] h-[50px]">
              <img
                src={croppedImage}
                alt="Cropped Preview"
                className="w-full h-full object-cover rounded border border-gray-300"
              />
            </div>
          </>
        ) : (
          <div className="w-[50px] h-[50px] text-sm rounded flex items-center justify-center border-2 border-gray-400 text-gray-600  bg-gray-100">
            No Image
          </div>
        )}
      </div>
      {error && <p className="text-red-500 text-[12px] mt-2">{error}</p>}
      {errors?.[`product_image_${index}`] && <p className="text-red-500 text-[12px] mt-2">{errors?.[`product_image_${index}`]}</p>}


      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCropCancel}
        className="modalForm"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h3 className="text-2xl font-bold mb-4">Crop Your Image</h3>
        <div className="flex gap-6">
          <div className="relative w-full h-[400px] sm:w-[400px] sm:h-[400px] bg-gray-100">
            {image && (
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            )}
          </div>
          <div className="w-[148px] h-[148px]">
            <div
              className="img-preview w-full h-full object-cover rounded border border-gray-300"
              style={{ height: "100%" }}
            />
          </div>
        </div>

        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="bg-gray-700 text-white  hover:scale-105 px-4 border-none py-2 rounded"
            onClick={handleCropCancel}
          >
            Cancel
          </button>
          <button
            className="bg-btnColor border-none hover:scale-105 text-white px-4 py-2 rounded"
            onClick={handleCropConfirm}
          >
            OK
          </button>
        </div>

        {/* <div className="flex justify-end mt-4 space-x-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={handleCropCancel}
          >
            Cancel
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={handleCropConfirm}
          >
            OK
          </button>
        </div> */}
      </Modal>
    </div>
  );
};

export default CropProductImage;
