import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchDataWordsRedux: "",
    searchDataTagsRedux: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchDataWordsRedux(state, action) {
      state.searchDataWordsRedux = action.payload;
    },
    setSearchDataTagsRedux(state, action) {
      state.searchDataTagsRedux = action.payload;
    },
    clearSearch(state) {
      state.searchDataWordsRedux = "";
      state.searchDataTagsRedux = [];
    },
  },
});

export const { setSearchDataWordsRedux, setSearchDataTagsRedux, clearSearch } = searchSlice.actions;

export default searchSlice.reducer;
