import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import StarRatings from 'react-star-ratings';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import _ from 'lodash';

import '../Dashboard/Modal/style.css'
import { assessmentFeedback, fetchComment, fetchTagList } from '../../Components/action/common';



function AssessmentEditNewModal({ show, onHide, assessment, fetchAssessmentDetail, params }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [numberOfStar, setNumberOfStar] = useState(assessment?.rating)
  const [ratingNumber, setRatingNumber] = useState(assessment?.pigRating)
  const [refUserName, setRefUserName] = useState(assessment?.refUserName)
  const [commentValue, setCommentValue] = useState(null)
  const [isLoader, setIsLoader] = useState(false)


  useEffect(() => {
    console.log( assessment,'aaaa')
    fetchCommentsList();
    getTags();
    // let data = assessment?.tagNames?.split(',');

    // if(data.length > 0){
    //   setSelectedTags(data);
    // }
  }, [assessment])

  const onSubmitFeedBack = async () => {
    setIsLoader(true)
    let requestBody = {
      "refUserId": assessment?.id,
      "refUserName": refUserName,
      "tags": selectedTags
    }
   
    try {
      await assessmentFeedback(requestBody)
      setIsLoader(false)
      fetchAssessmentDetail()
      onHide()
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const fetchCommentsList = async () => {
    try {
      let response = await fetchComment({ "refUserId": assessment?.id })
      console.log(response?.data?.responseData, "response")
    } catch ({ response }) {

    }
  }
  const getTags = async () => {
    try {
      let response = await fetchTagList();
      let tags = response?.data?.responseData;
      console.log(response?.data?.responseData, "rrrrrrrr")
      setTagList(tags);
      // let dd = [];

        // dd = tags?.filter((obj) => {
        //   if(assessment?.tagNames?.split(',').indexOf(obj.id?.toString()) !== -1){
        //     return true
        //   }
        // })
        const dd = tags?.filter((obj) => {
          const tagNamesArray = assessment?.tagNames?.split(",")?.map((name) => name.trim()); 
          return tagNamesArray?.includes(obj.name?.toString()); 
        });
        
        console.log('ddddd', dd)
        setSelectedTags(dd);
      
    } catch ({ response }) {

    }
  }



  const updateTags = (obj) => {
    setSelectedTags(obj);

  }


  console.log('selectedTags', selectedTags)


  return (
    <Modal className="modala fade in" style={{ display: 'block', paddingLeft: '0px' }} show={show} onHide={() => { onHide() }} backdrop="static">

      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>×</font></font></span></button>
          <h4 className="modal-title" id="assignModalLabel"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Categories</font></font></h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <form className="changePasswordForm" onSubmit={handleSubmit(onSubmitFeedBack)}>
            <div className="voucher_outer">
              <div className="row">

                <div className="form-group">
                  <label className="control-label">Name</label>
                  <div>
                    <input type="text" className="form-control input-lg" name="name" defaultValue={assessment?.name} onChange={(e) => setRefUserName(e.target.value)} />
                    <div className="error error_first_name" >{errors?.name?.message}</div>
                  </div>
                </div>

                <div className="form-group">
                
                </div>
                <div className="form-group" style={{ marginBottom: "10px", clear: "both", float: "left", width: "100%" }}>

                  <div className='col-sm-12'>
                    <label className="control-label"><font style={{ verticalAlign: 'inherit' }}>Tags</font></label>
                    <Select
                      getOptionLabel={(obj) => obj.name}
                      getOptionValue={(obj) => obj.id}
                      closeMenuOnSelect={false}
                      value={selectedTags}
                      isMulti
                      options={tagList}
                      onChange={(obj) => {
                        updateTags(obj);
                      }}
                    />
                    
                  </div>
                </div>

                
              </div>
            </div>
            <div >
              <button type="submit" className="btn btn-primary modelChangePasswordSave loadingbtns">{isLoader ? 'Loading...' : 'Save'}</button>
            </div>

          </form>
        </div>

      </div>
    </Modal>
  )
}

export default AssessmentEditNewModal

