import React, { useEffect, useState } from "react";
import CropImage from "./FormElements/CropImage";
import CropProductImage from "./FormElements/CropProductImage";
import { postBecomeSpeaker } from "../../Components/action/becomeSpeakerAction";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const socialMediaPlatforms = [
  {
    name: "Facebook",
    id: "facebook_link",
    imgSrc: "/images/becomeS/facebook_input.png",
  },
  {
    name: "Instagram",
    id: "instagram_link",
    imgSrc: "/images/becomeS/instagram_input.png",
  },
  {
    name: "Twitter",
    id: "twitter_link",
    imgSrc: "/images/becomeS/twitter_input.png",
  },
  {
    name: "Youtube",
    id: "youtube_link",
    imgSrc: "/images/becomeS/youtube_input.png",
  },
  {
    name: "Vimeo",
    id: "vimeo_link",
    imgSrc: "/images/becomeS/vimeo_input.png",
  },
  {
    name: "Pinterest",
    id: "pinterest_link",
    imgSrc: "/images/becomeS/pinterest_input.png",
  },
  { name: "Xing", id: "xing_link", imgSrc: "/images/becomeS/xing_input.png" },
  {
    name: "Linkedin",
    id: "linkedin_link",
    imgSrc: "/images/becomeS/linkedin_input.png",
  },
  {
    name: "Telegram",
    id: "telegram_link",
    imgSrc: "/images/becomeS/telegram_input.png",
  },
  {
    name: "Podcast",
    id: "podcast_link",
    imgSrc: "/images/becomeS/podcast_input.png",
  },
  {
    name: "Spotify",
    id: "spotify_link",
    imgSrc: "/images/becomeS/spotify_input.png",
  },
  {
    name: "Clubhouse",
    id: "clubhouse_link",
    imgSrc: "/images/becomeS/clubhouse_input.png",
  },
  {
    name: "TikTok",
    id: "tiktok_link",
    imgSrc: "/images/becomeS/tiktok_input.png",
  },
];

const emailListData = [
  "Small List",
  "500 - 2,000",
  "2,001 - 5,000",
  "5,001 - 10,000",
  "10,001 - 20,000",
  "20,001 - 50,000",
  "Over 50,000",
];

const productData = [
  { id: 1, title: "1: Product Title" },
  { id: 2, title: "2: Product Title" },
  { id: 3, title: "3: Product Title" },
];

const BecomeSpeakerForm = ({ dataSettings }) => {
  const [formData, setFormData] = useState({
    designation_name: "",
    first_name: "",
    last_name: "",
    expert_short_description: "",
    affiliate_digi_id: "",
    expert_email: "",
    phone_number: "",
    user_profile_picture: "",
    long_description: "",
    email_list: "",
    home_page_term: "",
    home_page_link: "",
    newsletter_term: "",
    newsletter_link: "",
    freebie_term: "",
    freebie_link: "",
    contact_email_term: "",
    contact_email_address: "",
    facebook_link: "",
    instagram_link: "",
    twitter_link: "",
    youtube_link: "",
    vimeo_link: "",
    pinterest_link: "",
    xing_link: "",
    linkedin_link: "",
    telegram_link: "",
    podcast_link: "",
    spotify_link: "",
    clubhouse_link: "",
    tikTok_link: "",
    product_title: {},
    product_link: {},
    product_featured_image: {},
    jaCheckBox1: false,
    jaCheckBox2: false,
    jaCheckBox3: false,
  });

  const navigate = useNavigate();
  const linkUrlInput = [
    {
      label: "Homepage • displayed term",
      tooltip: "Email",
      icon: "/images/becomeS/homepageLink.svg",
      inputId: "home_page_term",
      inputName: "home_page_term",
      value: formData.home_page_term,
      errorClass: "error_home_page_term",
      urlLabel: "Link/URL • Homepage",
      urlId: "home_page_link",
      urlName: "home_page_link",
      urlValue: formData.home_page_link,
      urlErrorClass: "error_home_page_link",
    },
    {
      label: "Newsletter • displayed term",
      tooltip: "Email",
      icon: "/images/becomeS/newsletterLink.svg",
      inputId: "newsletter_term",
      inputName: "newsletter_term",
      value: formData.newsletter_term,
      errorClass: "error_link_text_second",
      urlLabel: "Link/URL • Newsletter opt-in page",
      urlId: "newsletter_link",
      urlName: "newsletter_link",
      urlValue: formData.newsletter_link,
      urlErrorClass: "error_newsletter_link",
    },
    {
      label: "Freebie • displayed term",
      tooltip: "Email",
      icon: "/images/becomeS/freebieLink.svg",
      inputId: "freebie_term",
      inputName: "freebie_term",
      value: formData.freebie_term,
      errorClass: "error_freebie_term",
      urlLabel: "Link/URL • Freebie Page",
      urlId: "freebie_link",
      urlName: "freebie_link",
      urlValue: formData.freebie_link,
      urlErrorClass: "error_freebie_link",
    },
    {
      label: "Contact email • displayed term",
      tooltip: "Email",
      icon: "/images/becomeS/contact_email.svg",
      inputId: "contact_email_term",
      inputName: "contact_email_term",
      value: formData.contact_email_term,
      errorClass: "error_contact_email_term",
      urlLabel: "Contact Email address",
      urlId: "contact_email_address",
      urlName: "contact_email_address",
      urlValue: formData.contact_email_address,
      urlErrorClass: "error_contact_email_address",
    },
  ];

  const [errors, setErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {}, [isSubmited]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // console.log("Input changed:",name, value);

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const updateProfilePicture = (croppedImage) => {
    setFormData((prev) => ({
      ...prev,
      user_profile_picture: croppedImage,
    }));
  };

  const validateForm = () => {
    let errorList = {};
    let isValid = true;

    if (!formData.first_name) {
      isValid = false;
      errorList.first_name = "First Name is required";
    }

    if (!formData.last_name) {
      isValid = false;
      errorList.last_name = "Last Name is required";
    }
    if (!formData.affiliate_digi_id) {
      isValid = false;
      errorList.affiliate_digi_id = "Digistore-ID is required";
    }

    if (!formData.expert_email) {
      isValid = false;
      errorList.expert_email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.expert_email)) {
      isValid = false;
      errorList.expert_email = "Please enter a valid email address";
    }
    if (
      dataSettings?.termConditions?.[0].status != false &&
      !formData.jaCheckBox1
    ) {
      isValid = false;
      errorList.jaCheckBox1 =
        "Please confirm the terms and conditions. Thank you.";
    }
    if (
      dataSettings?.termConditions?.[1].status != false &&
      !formData.jaCheckBox2
    ) {
      isValid = false;
      errorList.jaCheckBox2 =
        "Please confirm the terms and conditions. Thank you.";
    }
    if (
      dataSettings?.termConditions?.[2].status != false &&
      !formData.jaCheckBox3
    ) {
      isValid = false;
      errorList.jaCheckBox3 =
        "Please confirm the terms and conditions. Thank you.";
    }

    let productList = [];
    [0, 1, 2].forEach((item) => {
      let product = {};
      product.title = formData?.product_title?.[`product_title_${item}`] || "";
      product.link = formData?.product_link?.[`product_link_${item}`] || "";
      product.image = formData?.product_featured_image?.[`p${item}`] || "";
      productList.push(product);
    });

    productList.forEach((product, index) => {
      // If image is provided, validate title and link
      if (product.image) {
        if (!product.title && !product.link) {
          // Both title and link are missing
          isValid = false;
          errorList[`product_title_${index}`] = `Product ${
            index + 1
          } title is required if an image is provided.`;
          errorList[`product_link_${index}`] = `Product ${
            index + 1
          } link is required if an image is provided.`;
        } else {
          // If only title is missing
          if (!product.title) {
            isValid = false;
            errorList[`product_title_${index}`] = `Product ${
              index + 1
            } title is required if an image is provided.`;
          }

          // If only link is missing
          if (!product.link) {
            isValid = false;
            errorList[`product_link_${index}`] = `Product ${
              index + 1
            } link is required if an image is provided.`;
          }
        }
      } else if (product.title || product.link) {
        errorList[`product_image_${index}`] = `Product ${
          index + 1
        } image is required.`;
        // If image is missing, you can either validate title and link, or skip it
        if (!product.title && !product.link) {
          isValid = false;
          errorList[`product_title_${index}`] = `Product ${
            index + 1
          } title is required.`;
          errorList[`product_link_${index}`] = `Product ${
            index + 1
          } link is required.`;
        } else {
          // If title is missing when image is missing
          if (!product.title) {
            isValid = false;
            errorList[`product_title_${index}`] = `Product ${
              index + 1
            } title is required.`;
          }

          // If link is missing when image is missing
          if (!product.link) {
            isValid = false;
            errorList[`product_link_${index}`] = `Product ${
              index + 1
            } link is required.`;
          }
        }
      }
    });

    setErrors(errorList);
    return isValid;
  };

  const submitFormApplication = async () => {
    if (validateForm()) {
      let dataToSend = {};
      let copyFormData = { ...formData };
      dataToSend.expertUserDigistoreId = copyFormData?.affiliate_digi_id;
      dataToSend.expertUserEmail = copyFormData?.expert_email;
      dataToSend.name =
        copyFormData?.first_name + " " + copyFormData?.last_name;
      delete copyFormData?.affiliate_digi_id;
      delete copyFormData?.expert_email;
      dataToSend.detailJsonResponse = { ...copyFormData } ?? {};

      let productList = [];
      [0, 1, 2].forEach((item) => {
        let product = {};
        product.title =
          copyFormData?.product_title?.[`product_title_${item}`] || "";
        product.link =
          copyFormData?.product_link?.[`product_link_${item}`] || "";
        product.image =
          copyFormData?.product_featured_image?.[`p${item}`] || "";
        productList.push(product);
      });

      productList = productList.filter(
        (product) => product.title || product.link || product.image
      );      

      dataToSend.detailJsonResponse.productList = productList;

      console.log(dataToSend, "dddd");

      await postBecomeSpeaker(dataToSend);
      toast.success("Form sumbitted successfully!");

      const resetFormData = Object.entries(formData).reduce(
        (acc, [key, value]) => {
          if (typeof value === "object" && value !== null) {
            acc[key] = {};
          } else if (typeof value === "boolean") {
            acc[key] = false;
          } else {
            acc[key] = "";
          }
          return acc;
        },
        {}
      );

      setFormData(resetFormData);
      setIsSubmited(true);
      navigate("/");
    } else {
      console.log("Form has errors", errors);
      setIsSubmited(true);
      return;
    }
  };

  const productInputChange = (e) => {
    const { name, value } = e.target;

    const [bName, title, index] = name.split("_");
    const baseName = bName + "_" + title;

    setFormData((prevState) => {
      const updatedField = { ...prevState[baseName] };
      updatedField[`${baseName}_${index}`] = value;

      return {
        ...prevState,
        [baseName]: updatedField,
      };
    });
  };

  const updateProductPicture = (croppedImage, index) => {
    setFormData((prevState) => {
      const updatedImages = { ...prevState.product_featured_image };
      updatedImages[`p${index}`] = croppedImage;

      return {
        ...prevState,
        product_featured_image: updatedImages,
      };
    });
  };

  const uniqueErrors = [...new Set(Object.values(errors))];

  // console.log(dataSettings, "ssss");

  return (
    <>
      {dataSettings && (
        <article className="flex flex-col gap-10 mb-7">
          <div className="flex flex-wrap xl:flex-nowrap gap-[24px] px-4">
            <div className="flex flex-col w-full lg:w-[400px]">
              <label htmlFor="designation_name">
                Title (e.g., Prof., Dr., etc.)
              </label>
              <input
                type="text"
                id="designation_name"
                name="designation_name"
                value={formData.designation_name}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              <div className="text-red-500 text-[12px] mt-1 error_designation_name"></div>
            </div>

            <div className="flex flex-col w-full lg:w-[400px]">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              {errors?.first_name && (
                <div className="text-red-500 text-[12px] mt-1 error_first_name">
                  {errors?.first_name}
                </div>
              )}
            </div>

            <div className="flex flex-col w-full lg:w-[400px]">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              {errors?.last_name && (
                <div className="text-red-500 text-[12px] mt-1 error_first_name">
                  {errors?.last_name}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap xl:flex-nowrap gap-[24px] px-4">
            <div className="flex flex-col w-full lg:w-[50%] relative">
              <label
                htmlFor="expert_short_description"
                className="flex items-center"
              >
                Expert Short Description (&lt;40 Characters)
                <span
                  data-tooltip-id="tooltip-right"
                  data-tooltip-place="right"
                  className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                >
                  <img
                    src="/images/becomeS/question.png"
                    className="w-[20px] h-[20px] "
                  />
                  <span className="tooltiptext">
                    The expert abbreviation should be a very short and
                  </span>
                </span>
              </label>

              <input
                type="text"
                id="expert_short_description"
                name="expert_short_description"
                maxLength="40"
                value={formData.expert_short_description}
                onChange={handleInputChange}
                className="form-control input-lg"
              />
              <div className="text-red-500 text-[12px] mt-1 error_expert_short_description"></div>
            </div>

            <div className="flex flex-col w-full lg:w-[50%]">
              <label htmlFor="affiliate_digi_id" className="flex items-center">
                Digistore-ID
                <span
                  data-toggle="popover"
                  className="ml-2 text-blue-600 cursor-pointer"
                >
                  <img
                    src="/images/becomeS/question.png"
                    className="w-[20px] h-[20px]"
                  />
                </span>
              </label>
              <input
                type="text"
                id="affiliate_digi_id"
                name="affiliate_digi_id"
                className="form-control input-lg"
                value={formData.affiliate_digi_id}
                onChange={handleInputChange}
              />
              {errors?.affiliate_digi_id && (
                <div className="text-red-500 text-[12px] mt-1">
                  {errors?.affiliate_digi_id}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col px-4">
            <label htmlFor="expert_email" className="flex items-center">
              Email address of the expert
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>
            <input
              type="email"
              id="expert_email"
              name="expert_email"
              value={formData.expert_email}
              onChange={handleInputChange}
              className="form-control input-lg"
            />
            {errors?.expert_email && (
              <div className="text-red-500 text-[12px] mt-1">
                {errors?.expert_email}
              </div>
            )}
          </div>

          <div className="flex flex-col px-4 ">
            <label htmlFor="phone_number" className="">
              Mobile Number
            </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
              className="form-control input-lg"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <div className="text-red-500 text-[12px] mt-1 error_phone_number"></div>
          </div>

          <div className="flex flex-col px-4">
            <CropImage updateProfilePicture={updateProfilePicture} />
          </div>

          <div className="flex flex-col px-4">
            <label htmlFor="expert_desc" className="flex items-center">
              Description of the expert
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>
            <textarea
              type="text"
              id="expert_short_description"
              name="long_description"
              value={formData.long_description}
              onChange={handleInputChange}
              className="form-control input-lg"
            ></textarea>
            <div className="text-red-500 text-[12px] mt-1 error_long_description"></div>
          </div>

          <div className="flex flex-col px-4">
            <label className="mb-2">How large is your email list?</label>
            {emailListData.map((size) => (
              <div className="flex items-center gap-2" key={size}>
                <input
                  type="radio"
                  id={size}
                  name="email_list"
                  value={size}
                  checked={formData.email_list === size}
                  onChange={handleInputChange}
                />
                <label htmlFor={size} className="mt-4">
                  {size}
                </label>
              </div>
            ))}
          </div>

          <div className="flex flex-col px-4 gap-7">
            <label className="mb-2 flex">
              Direct links{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>

            {linkUrlInput.map((field, index) => (
              <div key={index} className="flex gap-16 flex-wrap lg:flex-nowrap">
                <div className="flex items-center gap-7 w-full lg:w-[400px]">
                  <img className="h-[27px] w-[27px] mt-9" src={field.icon} />
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor={field.inputId}
                      className="flex items-center"
                    >
                      {field.label}
                      <span
                        data-tooltip-id="tooltip-right"
                        data-tooltip-place="right"
                        className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                      >
                        <img
                          src="/images/becomeS/question.png"
                          className="w-[20px] h-[20px]"
                        />
                        <span className="tooltiptext">{field.tooltip}</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id={field.inputId}
                      name={field.inputName}
                      value={field.value}
                      onChange={handleInputChange}
                      className="form-control input-lg"
                    />
                    <div
                      className={`text-red-500 text-[12px] mt-1 ${field.errorClass}`}
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col w-full lg:w-[70%] ml-16 lg:ml-0 ">
                  <label htmlFor={field.urlId} className="flex items-center">
                    {field.urlLabel}
                    <span
                      data-tooltip-id="tooltip-right"
                      data-tooltip-place="right"
                      className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                    >
                      <img
                        src="/images/becomeS/question.png"
                        className="w-[20px] h-[20px]"
                      />
                      <span className="tooltiptext">{field.tooltip}</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id={field.urlId}
                    name={field.urlName}
                    value={field.urlValue}
                    onChange={handleInputChange}
                    className="form-control input-lg"
                  />
                  <div
                    className={`text-red-500 text-[12px] mt-1 ${field.urlErrorClass}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col px-4 gap-7">
            <label className="mb-2 flex">
              Social Media Links{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>

            {socialMediaPlatforms.map((platform) => (
              <div className="flex items-center gap-7 w-full" key={platform.id}>
                <img
                  className="h-[27px] w-[27px] mt-9"
                  src={platform.imgSrc}
                  alt={platform.name}
                />
                <div className="flex flex-col w-full">
                  <label htmlFor={platform.id} className="flex items-center">
                    {platform.name}
                  </label>
                  <input
                    type="text"
                    id={platform.id}
                    name={platform.id}
                    value={formData[platform.id] || ""}
                    onChange={handleInputChange}
                    className="form-control input-lg"
                    required
                  />
                  <div
                    className={`text-red-500 text-[12px] mt-1 error_${platform.id}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col px-4 gap-7 mt-10">
            <label className="mb-2 flex">
              Products • Services • Courses{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>

            <div className="flex flex-col gap-[24px] px-4">
              {productData.map((product, index) => (
                <div key={product.id} className="flex flex-wrap gap-6">
                  <div className="flex flex-col w-full lg:w-[400px]">
                    <label
                      className="mb-2 flex"
                      htmlFor={`product_title_${index}`}
                    >
                      {product.title}
                      <span
                        data-tooltip-id="tooltip-right"
                        data-tooltip-place="right"
                        className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                      >
                        <img
                          src="/images/becomeS/question.png"
                          className="w-[20px] h-[20px]"
                        />
                        <span className="tooltiptext">
                          Product title explanation
                        </span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id={`product_title_${index}`}
                      name={`product_title_${index}`}
                      value={
                        formData?.product_title[`product_title_${index}`] || ""
                      }
                      onChange={productInputChange}
                      className="form-control input-lg"
                    />
                    {errors?.[`product_title_${index}`] && (
                      <div className="text-red-500 text-[12px] mt-1">
                        {errors?.[`product_title_${index}`]}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col w-full lg:w-[400px]">
                    <label
                      className="mb-2 flex"
                      htmlFor={`product_link_${index}`}
                    >
                      Link to the product
                      <span
                        data-tooltip-id="tooltip-right"
                        data-tooltip-place="right"
                        className="ml-2 text-blue-600 cursor-pointer tooltipForm"
                      >
                        <img
                          src="/images/becomeS/question.png"
                          className="w-[20px] h-[20px]"
                        />
                        <span className="tooltiptext">Link explanation</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id={`product_link_${index}`}
                      name={`product_link_${index}`}
                      value={
                        formData?.product_link[`product_link_${index}`] || ""
                      }
                      onChange={productInputChange}
                      required
                      className="form-control input-lg"
                    />
                    {errors?.[`product_link_${index}`] && (
                      <div className="text-red-500 text-[12px] mt-1">
                        {errors?.[`product_link_${index}`]}
                      </div>
                    )}
                  </div>

                  <div className="w-[300px]">
                    <CropProductImage
                      isSubmited={isSubmited}
                      updateProductPicture={(croppedImage) =>
                        updateProductPicture(croppedImage, index)
                      }
                      errors={errors}
                      index={index}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col px-4 gap-7">
            <label className="mb-2 flex">
              Please confirm the terms and conditions.{" "}
              <span
                data-tooltip-id="tooltip-right"
                data-tooltip-place="right"
                className="ml-2 text-blue-600 cursor-pointer tooltipForm"
              >
                <img
                  src="/images/becomeS/question.png"
                  className="w-[20px] h-[20px] "
                />
                <span className="tooltiptext">Emial</span>
              </span>
            </label>

            {dataSettings?.termConditions?.map((checkbox, index) => {
              if (checkbox?.status === true) {
                return (
                  <label className="flex items-center gap-3" key={index}>
                    <input
                      type="checkbox"
                      id={checkbox.id}
                      name={checkbox.id}
                      checked={formData[checkbox.id]}
                      onChange={handleInputChange}
                      className="jaCheckBox"
                    />
                    <span className="">
                      {checkbox.label}
                      {errors[checkbox.id] && (
                        <div className="text-red-500 font-normal text-[12px] mt-1">
                          {errors[checkbox.id]}
                        </div>
                      )}
                    </span>
                  </label>
                );
              }
              return null; // Ensure we return null if the condition is not met
            })}
          </div>

          <div className="text-center">
            <button className="btn-color-c" onClick={submitFormApplication}>
              <span>Submit Application</span>
              <span className="ml-2">&gt;&gt;</span>
            </button>
          </div>

          {/* {Object.keys(errors).length > 0 && (
        <div className="text-red-500 text-[12px]">
          {Object.values(errors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )} */}

          {uniqueErrors.length > 0 && (
            <div className="text-red-500 text-[12px]">
              {uniqueErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
        </article>
      )}
    </>
  );
};

export default BecomeSpeakerForm;
