import './TailwindComponent.css';
const BecomeWelcome = ({dataSettings}) => {
  return (
    <div className="text-center mt-[70px]">
      <h1 className=" font-bold text-[30px] leading-[1.1]">{dataSettings?.titleHeader}</h1>
      <div className='mt-14' dangerouslySetInnerHTML={{
                          __html:
                          dataSettings?.titleParagraph ||
                            "",
                        }}></div>

      <div className="mt-32 flex justify-center">
        <iframe
          className="rounded-[20px]"
          src={dataSettings?.videoLink??""}
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div class=" mt-[50px]">
        <div class=" flex justify-center flex-wrap mb-16 gap-[30px] ">
          {dataSettings?.sections.map((steps,index) => {
            return (
              <div class="bg-white rounded-[10px] card-guide p-[20px] max-w-[360px]" key={index}>
                <h3 class="text-[24px] font-semibold mb-2">
                  {steps?.title}
                </h3>
                <p class="text-gray-600">
                 {steps?.paragraph}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default BecomeWelcome;
