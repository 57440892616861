import React from "react";
import SideBar from "../Dashboard/SideBar";

const Welcome = () => {
  return (
    <>
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="w-full h-full overflow-auto">
            <div className="">
              <span className="text-blue-400">Expert</span> / Experts A short
              welcome and a short guide
            </div>
            <div className="mt-16 w-full flex justify-center overflow-auto">
              <div className="w-[70%] flex flex-col justify-center">
                <p className="text-[16px] text-center font-semibold">
                  Please apply here, enter all the required information, and we
                  will get back to you promptly. Thank you.
                </p>

                <div className="flex justify-center mt-6">
                  <div className="relative w-full max-w-[650px]">
                    <iframe
                      src="https://player.vimeo.com/video/810871377?h=8b772be37a"
                      width="640"
                      height="360"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>

                <p className="mt-10">
                  We're glad you're here, in the expert backend of our
                  conference software platform from{" "}
                  <strong className="text-blue-400">
                    TeileDeineBotschaft.de
                  </strong>
                  . Please take a quick look at the video. Here you can make the
                  settings for your profile, your interview, and the products
                  you want to offer. If you have any questions, please contact{" "}
                  <strong className="text-blue-400">
                    gregor@teiledeinebotschaft.de
                  </strong>
                  . Best regards, Gregor Dorsch (Managing Director and Founder
                  of TeileDeineBotschaft.de)
                </p>

                {/* <div className="flex justify-between py-4 border-t-2 mt-5">
                <span>
                  © 2024 Elevation Group GND LTD, www.teiledeinebotschaft.de
                </span>
                <span>Version: 7.24.11.26</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
