import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  persistStore, persistReducer,
  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { BASE_URL } from '../Components/Common/constants';
// import { clearSession } from '../utilities';
import { rootReducer } from './slices';
import tokenTimeSlice from './slices/tokenTimeSlice';
import toast from 'react-hot-toast';
// import logger from "react-logger"


// creating and persisting redux store
const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:persistedReducer,

  // reducer:{ persistedReducer,timeTokenData:tokenTimeSlice},
  


  // Restrict Redux Devtool in Production
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),

});

export const persistor = persistStore(store);

// ------------------------ axios interceptor request -------------------------
axios.interceptors.request.use((request) => {
  console.log(request,"request", request.headers)
  // let accessToken = typeof window !== "undefined" ? window.localStorage.getItem("token") : null;

 
  // let UTCOffset = new Date().getTimezoneOffset();
  // console.log(UTCOffset,"UTCOffset", request.headers)
 
  // request.headers.set('authorization', accessToken)
  // request.headers.set('utcoffset' , UTCOffset)
  // request.headers.set('language' , "en")

  if (!request.url.includes('/attachment/upload')) {
    const accessToken = typeof window !== "undefined" ? window.localStorage.getItem("token") : null;
    const UTCOffset = new Date().getTimezoneOffset();

    request.headers.set('authorization', accessToken);
    request.headers.set('utcoffset', UTCOffset);
    request.headers.set('language', "en");
  }
 
  request.baseURL = BASE_URL;
  
  console.log(request,'afterrr')
  return request;
},
  (error) => {
    return Promise.reject(error);
  }
);

// ------------------------ axios interceptor response -------------------------
// axios.interceptors.response.use((response) => {
//   return response;
// },
//   (error) => {
//     switch (error.response?.status) {
//       case 401:
//         // clearSession();
//         break;
//       case 400:
//         // toast.error(error.response.data.message);
//         break;
//       default:
//         break;
//       // toast.error('Something really went wrong, please try again');
//     }
//     return Promise.reject(error);
//   }
// );



axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response?.status) {
      case 401:
        // Clear cache and localStorage
        console.warn('Unauthorized: Clearing session and redirecting to home.');
        if (typeof window !== "undefined") {
          window.localStorage.clear(); // Clear all localStorage data
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName); // Clear browser cache
            });
          });
        }

        // Display toast notification
        toast.error('Your session has expired. Please log in again.');

        // Navigate to home page
        if (typeof window !== "undefined") {
          window.location.href = "/"; // Redirect to home page
        }
        break;

      case 400:
        // Optionally handle bad requests
        toast.error(error.response?.data?.message || 'Bad request.');
        break;

      default:
        // Handle other errors
        toast.error('Something went wrong. Please try again.');
        break;
    }

    return Promise.reject(error); // Propagate the error to the caller
  }
);



