import React from "react";
import { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import {
  accountAssessments,
  assessmentDelete,
  assessmentRestore,
  accountFreeze,
  getSimilarRefUsers,
  setFavouriteStatus,
} from "../../Components/action/common";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import { useDispatch, useSelector } from "react-redux";
//import { setAccounts, setAssessmentAccountList, setAssessmentList, setAssessmentTitle, setTotalAssismentListData } from '../../redux/slices/admin';
import StarRatings from "react-star-ratings";
import { _ } from "lodash";
import Select from "react-select";
import AssessmentEdit from "../Dashboard/Modal/AssessmentEdit";
import CopyToCongressModal from "../Dashboard/Modal/CopyToCongressModal";
import AddNewExpertModal from "../Dashboard/Modal/AddNewExpertModal";
import EditNewExpertModal from "../Dashboard/Modal/EditNewExpertModal";
import { useNavigate, Navigate } from "react-router-dom";
import Comments from "../Dashboard/Modal/Comments";
import { Fragment } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Rating } from "react-simple-star-rating";
import { GiSheep } from "react-icons/gi";
import { fetchAllTags } from "../../Components/action/tags";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AssessmentMerge from "../Dashboard/Modal/AssessmentMerge";
import { BASE_URL } from "../../Components/Common/constants";
import ManageRecommendedTag from "../Dashboard/Modal/ManageRecommendedTag";
import Switch from "react-switch";

var CancelToken = axios.CancelToken;
var cancel;
function Assessment({ pageType }) {
  console.log(pageType, "pageType");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const { assessmentList, accounts, assessmentTitle, assessmentAccountList } = useSelector(state => state.admin)

  const { loggedInData } = useSelector((state) => state.admin);
  const [assessmentTitle, setAssessmentTitle] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [assessmentListCsv, setAssessmentListCsv] = useState([]);
  const [assessmentAccountList, setAssessmentAccountList] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [ratingNumber, setRatingNumber] = useState(0);
  const [limits, setLimits] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [accountNameList, setAccountNameList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [getSortBy, setSortBy] = useState("refUserName");
  const [getSortOrder, setSortOrder] = useState("desc");
  const [recommendedFor, setRecommendedFor] = useState(null);
  const [getFavFlag, setFavFlag] = useState(0);
  const [addNewExpert, setAddNewExpert] = useState(false);
  const [editNewExpert, setEditNewExpert] = useState(false);

  let qParams = {
    pageNumber: 1,
    limit: 10,
    sortOrder: "desc",
    sortBy: "total",
    favFlag: getFavFlag,
  };
  if (pageType == "archieved") {
    qParams = Object.assign(qParams, { viewArchived: true });
  }
  const [params, setParams] = useState(qParams);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [copyToCongressModal, setCopyToCongressModal] = useState(false);
  // const [account]

  const [commentPopUp, setCommentPopUp] = useState(false);
  const [mergePopUp, setMergePopUp] = useState(false);

  const fetchTagsListing = async () => {
    try {
      let response = await fetchAllTags();
      console.log(
        response?.data?.responseData?.data,
        "response?.data?.responseData?.data"
      );
      setTagList(response?.data?.responseData?.data);
    } catch ({ response }) {
      // response.status===401 && navigate("/")
      // response.status===401 && localStorage.clear()
    }
  };

  // const fetchAssessmentDetail = async (params) => {
  //   //Check if there are any previous pending requests
  //   if (typeof cancelToken != typeof undefined) {
  //     cancelToken.cancel("Operation canceled due to new request.")
  //   }

  //   //Save the cancel token for the current request
  //   cancelToken = axios.CancelToken.source()

  //   try {
  //     //params = Object.assign(params, { "pageNumber": activePage, "limit": 10 });
  //     //let response = await accountAssessments(params)
  //     setLoading(true);
  //     let response = await axios.get(
  //       `/assessment`,
  //       { params: params, cancelToken: cancelToken.token } //Pass the cancel token to the current request
  //     )
  //     setLoading(false);
  //     console.log(response?.data?.responseData, 'dddddddd2222')
  //     let account = response?.data?.responseData?.accounts?.map((el) => {
  //       return el?.id
  //     })
  //     if (params.searchText) {
  //       setSearchText(params.searchText);
  //     } else {
  //       setSearchText(null)
  //     }
  //     setAccountNameList(account)
  //     setAssessmentTitle(response?.data?.responseData?.assessmentTitle);
  //     setAssessmentList(response?.data?.responseData?.assessmentDetail);
  //     setAssessmentAccountList(response?.data?.responseData?.accounts);
  //     setLimits(response?.data?.responseData?.limit)
  //     setTotalRecords(response?.data?.responseData?.totalRecords)
  //     setTotalPages(response?.data?.responseData?.totalPages)
  //     setCurrentPage(response?.data?.responseData?.page);
  //   } catch ({ response }) {
  //     if (axios.isCancel()) return;
  //     setLoading(false);
  //   }
  // }

  const fetchAssessmentDetail = (params) => {
    if (typeof cancel == "function") {
      cancel();
    }
    setLoading(true);
    axios({
      method: "GET",
      params: params,
      url: "/assessment",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        setLoading(false);
        let account = response?.data?.responseData?.accounts?.map((el) => {
          return el?.id;
        });
        if (params.searchText) {
          setSearchText(params.searchText);
        } else {
          setSearchText(null);
        }
        setAccountNameList(account);
        setAssessmentTitle(response?.data?.responseData?.assessmentTitle);
        setAssessmentList(response?.data?.responseData?.assessmentDetail);
        setAssessmentAccountList(response?.data?.responseData?.accounts);
        setLimits(response?.data?.responseData?.limit);
        setTotalRecords(response?.data?.responseData?.totalRecords);
        setTotalPages(response?.data?.responseData?.totalPages);
        setCurrentPage(response?.data?.responseData?.page);
      })
      .catch(({ response }) => {
        // response.status===401 && navigate("/")
        // response.status===401 && localStorage.clear()
        //setLoading(false);
        //if (axios.isCancel(err)) return;
      });
  };

  const search = (value) => {
    let updatedParams = Object.assign(params, {
      searchText: value,
      pageNumber: 1,
    });
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      fetchAssessmentDetail(updatedParams);
    }, 500);
    setTimer(newTimer);
    setParams(updatedParams);
  };

  const searchTag = (tagId) => {
    let updatedParams = Object.assign(params, { tagId: tagId, pageNumber: 1 });
    fetchAssessmentDetail(updatedParams);
    setParams(updatedParams);
  };

  const onViewAccountsCick = (assessment) => {
    navigate("/accounts", { state: { assessment: assessment } });
  };

  const handlePageChange = (pageNumber) => {
    let localParams = params;
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchAssessmentDetail(localParams);
  };

  const sortRecords = (sortBy, sortOrder) => {
    let localParams = params;
    //hardcoded to desc on client request

    if (sortBy == getSortBy) {
      if (getSortOrder == "asc") {
        sortOrder = "desc";
      } else {
        sortOrder = "asc";
      }
    } else {
      sortOrder = "desc";
    }

    localParams = Object.assign(localParams, {
      sortBy: sortBy,
      sortOrder: sortOrder,
    });
    setParams(localParams);
    setSortBy(sortBy);
    setSortOrder(sortOrder);
    fetchAssessmentDetail(localParams);

    //const [getSortBy, setSortBy] = useState('refUserName');
    //const [getSortOrder, setSortOrder] = useState('DESC');
  };

  useEffect(() => {
    fetchTagsListing();
  }, []);

  useEffect(() => {
    if (pageType == "archieved") {
      let p = { ...qParams };
      p = Object.assign(p, { viewArchived: true });
      setParams(p);

      fetchAssessmentDetail(p);
    } else {
      let p = { ...qParams };
      delete p.viewArchived;
      setParams(p);
      fetchAssessmentDetail(p);
    }
    // return () => {
    //   if (typeof cancelToken != typeof undefined) {
    //     cancelToken.cancel("Operation canceled due to new request.")
    //   }
    // };
  }, [pageType]);

  useEffect(() => {
    if (ratingNumber > 0) {
      for (let itr = 1; itr <= parseInt(ratingNumber); itr++) {
        const event = document.getElementById(itr.toString());
        event.style.backgroundImage = "url('/images/sheep-01.svg')";
        event.style.paddingBottom = "14px";
        // event.style.backgroundColor = 'orange'
      }
    }
  }, [ratingNumber]);

  const onDeleteAssesment = (account) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await assessmentDelete({ id: account?.id });
              fetchAssessmentDetail(params);
            } catch ({ response }) {
              //         response.status===401 && navigate("/")
              // response.status===401 && localStorage.clear()
            }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const onFreezeAccount = (account, status, index) => {
    confirmAlert({
      title: "Confirm to freeze/unfreeze",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await accountFreeze({
                id: account?.id,
                profileFreezed: status ? 1 : 0,
              });
              let dd = [...assessmentList];
              dd[index] = { ...dd[index], profileFreezed: status ? 1 : 0 };
              setAssessmentList(dd);
              //fetchAssessmentDetail(params)
            } catch ({ response }) {
              //         response.status===401 && navigate("/")
              // response.status===401 && localStorage.clear()
            }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const onRestoreAssesment = (account) => {
    confirmAlert({
      title: "Confirm to restore",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await assessmentRestore({ id: account?.id });
              fetchAssessmentDetail(params);
            } catch ({ response }) {
              //         response.status===401 && navigate("/")
              // response.status===401 && localStorage.clear()
            }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const downloadCsv = async (event, done) => {
    try {
      setCsvLoading(true);
      let params = { isCsv: 1 };
      if (searchText) {
        params = Object.assign(params, { searchText: searchText });
      }
      let response = await axios.get(
        `/assessment`,
        { params: params } //Pass the cancel token to the current request
      );
      setAssessmentListCsv(response?.data?.responseData?.data);
      setCsvLoading(false);
      setTimeout(() => {
        done(false);
      }, 1000);
    } catch ({ response }) {
      setCsvLoading(false);
      // response.status===401 && navigate("/")
      // response.status===401 && localStorage.clear()
    }
  };

  const formatAverage = (avg) => {
    let imageSrc = "";
    if (avg <= 9) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-01-No-list.svg";
    } else if (avg >= 10 && avg <= 19) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-02-20.svg";
    } else if (avg >= 20 && avg <= 34) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-03-25.svg";
    } else if (avg >= 35 && avg <= 64) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-04-50.svg";
    } else if (avg >= 65 && avg <= 84) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-05-75.svg";
    } else if (avg >= 85 && avg <= 99) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-06-100-.svg";
    } else if (avg >= 100 && avg <= 199) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-07-100+.svg";
    } else if (avg >= 200 && avg <= 299) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-08-200.svg";
    } else if (avg >= 300 && avg <= 399) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-09-300.svg";
    } else if (avg >= 400 && avg <= 449) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-10-400.svg";
    } else if (avg >= 450 && avg <= 499) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-11-500.svg";
    } else if (avg >= 500 && avg <= 900) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-12-500.svg";
    } else if (avg >= 901 && avg <= 1999) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-13-1000.svg";
    } else if (avg >= 2000) {
      imageSrc = "/images/Elitespeaker-Icon_LISTE-CAT-14-MAX.svg";
    } else {
      return "-";
    }
    return <img style={{ width: "80px" }} src={imageSrc} />;
  };

  const formatSeats = (seats) => {
    let imageSrc = "";
    if (seats <= 1) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-1-1.svg";
    } else if (seats == 2) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-2-2.svg";
    } else if (seats == 3) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-3-3.svg";
    } else if (seats >= 4 && seats <= 5) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-4-5.svg";
    } else if (seats >= 6 && seats <= 9) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-5-10.svg";
    } else if (seats >= 10 && seats <= 19) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT-6-10.svg";
    } else if (seats >= 20 && seats <= 29) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT7-20.svg";
    } else if (seats >= 30 && seats <= 49) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT8-30.svg";
    } else if (seats >= 50) {
      imageSrc = "/images/Elitespeaker-Icon_SEAT-CAT9-50.svg";
    } else {
      return "-";
    }
    return <img style={{ width: "80px" }} src={imageSrc} />;
  };

  if (!authentication()) return <Navigate to={"/"} />;

  console.log("loading.....", loading);

  const mergeHandler = async (id) => {
    setMergePopUp(true);
    try {
      const req = await getSimilarRefUsers(id);
      setLoading(true);
    } catch ({ response }) {
      // response.status===401 && navigate("/")
      // response.status===401 && localStorage.clear()

      setLoading(false);
    }
  };

  // const mergeHandler = (params) => {
  //   console.log(params,"params")
  //   if (typeof cancel == "function") {
  //     cancel();
  //   }
  // setMergePopUp(true)

  //   setLoading(true);
  //   axios({
  //     method: 'GET',
  //     params: params,
  //     url: `${BASE_URL}/getSimilarRefUsers`,
  //     cancelToken: new CancelToken(function executor(c) {
  //       cancel = c;
  //     }),

  //   }).then((response) => {
  //     setLoading(false);
  //   }).catch((err) => {
  //     setLoading(false);

  //   })
  // }

  const formatTags = (tagString) => {
    if (tagString) {
      let dataArray = tagString.split(",");
      let tags = "";
      let spans = dataArray.map((obj, index) => {
        if (index > 1 && index != dataArray.length - 1) {
          return;
        } else if (index > 2 && index == dataArray.length - 1) {
          return (
            <span title={tagString}>
              {" +"}
              {dataArray.length - 2} {"more"}
            </span>
          );
        }
        return <span className="tag_wrapper">{obj}</span>;
      });
      return spans;
    }
  };

  const setFavourite = async (id, status, index) => {
    try {
      let response = await setFavouriteStatus({
        id: id,
        isFavourite: status ? 1 : 0,
      });
      let dd = [...assessmentList];
      dd[index] = { ...dd[index], isFavourite: status ? 1 : 0 };
      setAssessmentList(dd);
      //fetchAssessmentDetail(params)
    } catch ({ response }) {
      // response.status===401 && navigate("/")
      // response.status===401 && localStorage.clear()
    }
    // confirmAlert({
    //   title: status == 1 ? 'Confirm to mark favourite' : 'Confirm to remove favourite',
    //   message: 'Are you sure to do this.',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: async () => {
    //         try {
    //           let response = await setFavouriteStatus({ "id": id, isFavourite: status ? 1 : 0 });
    //           let dd = [...assessmentList];
    //           dd[index] = { ...dd[index], isFavourite: status ? 1 : 0 }
    //           setAssessmentList(dd);
    //           //fetchAssessmentDetail(params)
    //         } catch ({ response }) {

    //         }
    //       }
    //     },
    //     {
    //       label: 'No',
    //     }
    //   ]
    // });
  };

  const manageTagModal = (id) => {
    setShowTagModal(true);
    setRecommendedFor(id);
  };

  const handleStatusChange = async (value, flag) => {
    try {
      let stat = flag == 0 ? 1 : 0;
      setFavFlag(stat);

      let updatedParams = Object.assign(params, { favFlag: stat });
      const newTimer = setTimeout(() => {
        fetchAssessmentDetail(updatedParams);
      }, 500);
      setParams(updatedParams);
    } catch ({ response }) {
      // response.status===401 && navigate("/")
      // response.status===401 && localStorage.clear()
    }
  };

  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            <div className=" filter_options">
              <h4 className="page-title">Assessment Management</h4>
              <div className="filter_options_inner">
                <div className="form-group">
                  {/* <form id="searchUserForm" className="searchUserForm" > */}

                  {/* <input type="hidden" name="_token" /> */}
                  <input
                    style={{ padding: "3px 0px 5px 5px" }}
                    type="text"
                    className="form-control"
                    name="searchUser"
                    placeholder="Search"
                    onChange={(e) => search(e?.target?.value)}
                  />

                  {/* </form> */}
                </div>
                {/*}
                <div className="form-group" style={{ minWidth: '100px' }}>
                   <div
                    className="userStatus"
                    data-status={1}
                    data-text="Deactivate"
                  >
                    <Switch
                      width={40}
                      height={14}
                      onChange={(e) =>
                        handleStatusChange(e, getFavFlag)
                      }
                      checked={getFavFlag}
                      className={"status_switch"}
                      onColor={"#6b172e"}
                    />
                  </div>
                </div>
                */}
                <div className="form-group" style={{ minWidth: "300px" }}>
                  <Select
                    options={tagList}
                    getOptionLabel={(opt) => opt?.name}
                    getOptionValue={(opt) => opt?.id}
                    isClearable={true}
                    onChange={(opt) => {
                      let tags = "";
                      if (opt && opt.length > 0) {
                        for (let [index, obj] of opt.entries()) {
                          if (index == 0) {
                            tags = obj.id;
                          } else {
                            tags = tags + "," + obj.id;
                          }
                        }
                      }
                      console.log(tags, "oooooooo");
                      searchTag(tags);
                    }}
                    isMulti={true}
                  />
                </div>
                {/* <div className="col-md-7" style={{ textAlign: "end" }}> */}

                {loggedInData?.role == "SUPER_ADMIN" && (
                  <button
                    className="btn btn-theme"
                    onClick={() => downloadCsv()}
                  >
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>Export</font>
                    </font>
                  </button>
                )}

                {(loggedInData?.role == "SUPER_ADMIN" ||
                  loggedInData?.role == "MANAGER") && (
                  <button
                    className="btn btn-theme"
                    onClick={() => setAddNewExpert(true)}
                  >
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>
                        Add Expert
                      </font>
                    </font>
                  </button>
                )}

                {loggedInData?.role == "VIEWER" && (
                  <div className=" filter_options">
                    <button
                      className="btn btn-theme"
                      style={{ marginBottom: "-21px", marginLeft: "auto" }}
                      onClick={() => manageTagModal(null)}
                    >
                      Neuen Tag vorschlagen
                    </button>
                  </div>
                )}
                {assessmentListCsv && assessmentListCsv.length > 0 && (
                  <CSVDownload data={assessmentListCsv} target="_blank">
                    Download me
                  </CSVDownload>
                )}
              </div>

              {/* </div> */}
            </div>

            <div className="userlisting row">
              <div className="table-responsive custom-center-ui scroll-table">
                {loading ? <div className="loading">Loading...</div> : <></>}

                <table
                  id="loginUser"
                  className="display dataTable"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="th-id">Rank</th>
                      <th className="th-digit">
                        {getFavFlag ? (
                          <img
                            src="/images/heart.png"
                            width="20px"
                            title="Click to mark unfavourite"
                            onClick={(e) => handleStatusChange(e, getFavFlag)}
                          />
                        ) : (
                          <img
                            src="/images/heart_blank.png"
                            width="20px"
                            title="Click to mark favourite"
                            onClick={(e) => handleStatusChange(e, getFavFlag)}
                          />
                        )}
                      </th>
                      <th className="th-email">
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "refUserName",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Name
                        </a>
                      </th>
                      <th className="th-digit"></th>
                      <th className="th-digit">
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "refUserDigistoreId",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          D24-ID
                        </a>
                      </th>
                      <th className="th-digit">
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "refUserEmail",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Email
                        </a>
                      </th>

                      <th className="th-total">
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "seats",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Seats Category
                        </a>
                      </th>
                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <th className="th-total">
                          <a href="#" className="sorting">
                            Seats
                          </a>
                        </th>
                      )}

                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <th className="th-total">
                          <a
                            href="#"
                            className="sorting"
                            onClick={(e) =>
                              sortRecords(
                                "total",
                                params.sortOrder == "asc" ? "desc" : "asc"
                              )
                            }
                          >
                            Total
                          </a>
                        </th>
                      )}

                      <th className="th-avg">
                        <a
                          href="#"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "average",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Invite Category
                        </a>
                      </th>
                      {loggedInData?.role == "SUPER_ADMIN" && (
                        <th className="th-total">
                          <a href="#" className="sorting">
                            Invites
                          </a>
                        </th>
                      )}

                      <th className="th-rating" style={{ minWidth: "165px" }}>
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "rating",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Rating
                        </a>
                      </th>
                      <th
                        className="th-pigrating"
                        style={{ minWidth: "200px" }}
                      >
                        <a
                          href="javascript:void(0)"
                          className="sorting"
                          onClick={(e) =>
                            sortRecords(
                              "pigRating",
                              params.sortOrder == "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Sheep Rating
                        </a>
                      </th>

                      <th>Tags</th>

                      <th className="th-edit" style={{ minWidth: "225px" }}>
                        Actions
                      </th>
                      {loggedInData?.role == "SUPER_ADMIN" &&
                        assessmentAccountList?.map((accounts, index) => {
                          console.log(accounts, "accounts");
                          const displayName =
                            accounts?.name?.length > 10
                              ? accounts?.name?.slice(0, 10) + "..."
                              : accounts?.name;

                          return (
                            <th className="th-assessment" key={index}>
                              <font style={{ verticalAlign: "inherit" }}>
                                <a
                                  data-tooltip-id={"my-tooltip" + index}
                                  href="#"
                                  className="sorting"
                                  style={{ wordBreak: "keep-all" }}
                                  onClick={(e) =>
                                    sortRecords(
                                      accounts?.id,
                                      params.sortOrder == "asc" ? "desc" : "asc"
                                    )
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: displayName?.replaceAll(
                                      " ",
                                      "&nbsp;"
                                    ),
                                  }}
                                />
                              </font>

                              <ReactTooltip
                                id={"my-tooltip" + index}
                                place="bottom"
                                content={accounts?.name}
                              />
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {assessmentList &&
                      assessmentList.length > 0 &&
                      assessmentList?.map((assessment, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              assessment?.profileFreezed &&
                              loggedInData?.role != "VIEWER"
                                ? "account_freezed"
                                : ""
                            }
                          >
                            <td className="user_unique_id">
                              {/* {assessment["refUserId"]} */}
                              {(parseInt(currentPage) - 1) * parseInt(limits) +
                                (index + 1)}
                            </td>

                            <td className="userStatus">
                              {assessment["isFavourite"] ? (
                                <img
                                  src="/images/heart.png"
                                  width="20px"
                                  title="Click to mark unfavourite"
                                  onClick={() => {
                                    setFavourite(
                                      assessment["id"],
                                      !assessment["isFavourite"],
                                      index
                                    );
                                  }}
                                />
                              ) : (
                                <img
                                  src="/images/heart_blank.png"
                                  width="20px"
                                  title="Click to mark favourite"
                                  onClick={() => {
                                    setFavourite(
                                      assessment["id"],
                                      !assessment["isFavourite"],
                                      index
                                    );
                                  }}
                                />
                              )}
                            </td>

                            <td className="ellipsis">
                              <span className="profile_image">
                                <img
                                  src={
                                    assessment["refUserPicture"] ||
                                    "/images/profile_pic.png"
                                  }
                                />
                              </span>
                              {assessment["refUserName"]}
                            </td>
                            <td className=" ellipsis">
                              {assessment["homePagelink"] ? (
                                <a
                                  href={assessment["homePagelink"]}
                                  target="_blank"
                                >
                                  <img
                                    style={{ width: "25px" }}
                                    src="/images/internet.svg"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              className="user_role_text ellipsis"
                              style={{ wordBreak: "keep-all !important" }}
                            >
                              {assessment["refUserDigistoreId"]}
                            </td>
                            <td className=" ellipsis">
                              {assessment["refUserEmail"]}
                            </td>

                            <td className="reset_status" reset_status={1}>
                              <p className="green-text">
                                {assessment["seats"] ? (
                                  formatSeats(assessment["seats"])
                                ) : (
                                  <>-</>
                                )}
                              </p>
                            </td>
                            {loggedInData?.role == "SUPER_ADMIN" && (
                              <td className="reset_status" reset_status={1}>
                                <p className="green-text">
                                  {assessment["seats"] ? (
                                    assessment["seats"]
                                  ) : (
                                    <>0</>
                                  )}
                                </p>
                              </td>
                            )}

                            {loggedInData?.role == "SUPER_ADMIN" && (
                              <td className="reset_status" reset_status={1}>
                                <p className="green-text">
                                  {assessment["total"]
                                    ? assessment["total"]
                                    : "-"}
                                </p>
                              </td>
                            )}

                            <td className="reset_status" reset_status={1}>
                              <p className="green-text">
                                {formatAverage(assessment["average"])}
                                {/* {assessment["average"] ? assessment["average"] : "-"} */}
                              </p>
                            </td>
                            {loggedInData?.role == "SUPER_ADMIN" && (
                              <td className="reset_status" reset_status={1}>
                                <p className="green-text">
                                  {assessment["average"] ? (
                                    assessment["average"]
                                  ) : (
                                    <>0</>
                                  )}
                                </p>
                              </td>
                            )}

                            <td>
                              <span className="star-rating">
                                <Rating
                                  initialValue={assessment?.rating}
                                  readonly
                                  size={25}
                                />
                              </span>
                            </td>
                            <td>
                              <span className="star-rating">
                                <Rating
                                  initialValue={assessment?.pigRating}
                                  fillIcon={
                                    <GiSheep size={25} color={"#000000"} />
                                  }
                                  emptyIcon={
                                    <GiSheep size={25} color={"#cccccc"} />
                                  }
                                  readonly
                                />
                              </span>
                            </td>

                            <td>{formatTags(assessment?.tagNames)}</td>

                            <td className="action-icons">
                              {pageType == "archieved" ? (
                                <>
                                  {(loggedInData?.role == "SUPER_ADMIN" ||
                                    loggedInData?.role == "MANAGER") && (
                                    <>
                                      <i
                                        title="Restore"
                                        className="fa fa-window-restore"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          onRestoreAssesment(assessment)
                                        }
                                      ></i>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(loggedInData?.role == "SUPER_ADMIN" ||
                                    loggedInData?.role == "MANAGER") && (
                                    <>
                                      <img
                                        title={
                                          assessment["profileFreezed"]
                                            ? "Unfreeze account"
                                            : "Freeze account"
                                        }
                                        src="./images/lock.png"
                                        onClick={() => {
                                          onFreezeAccount(
                                            assessment,
                                            !assessment["profileFreezed"],
                                            index
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                  {(loggedInData?.role == "SUPER_ADMIN" ||
                                    loggedInData?.role == "MANAGER") && (
                                    <img
                                      title="Edit"
                                      src="./images/Tag.svg"
                                      onClick={() => {
                                        setCommentPopUp(true);
                                        setAssessmentDetails(assessment);
                                      }}
                                    />
                                  )}
                                  <img
                                    title="Remarks"
                                    src="./images/chat.png"
                                    onClick={() => {
                                      setCommentModal(true);
                                      setAssessmentDetails(assessment);
                                    }}
                                  />

                                  {loggedInData?.role == "SUPER_ADMIN" && (
                                    <>
                                      <img
                                        title="Email list"
                                        src="./images/list.png"
                                        onClick={() =>
                                          onViewAccountsCick(assessment)
                                        }
                                      />
                                    </>
                                  )}
                                  {(loggedInData?.role == "SUPER_ADMIN" ||
                                    loggedInData?.role == "MANAGER") && (
                                    <>
                                      <img
                                        title="Delete"
                                        src="./images/delete.png"
                                        onClick={() =>
                                          onDeleteAssesment(assessment)
                                        }
                                      />
                                    </>
                                  )}
                                  <img
                                    title="Transporter"
                                    src="./images/Truck.svg"
                                    onClick={() => {
                                      setCopyToCongressModal(true);
                                      setAssessmentDetails(assessment);
                                    }}
                                  />

                                  <img
                                    title="Edit"
                                    src="./images/edit.svg"
                                    onClick={() => {
                                      setEditNewExpert(true);
                                      setAssessmentDetails(assessment);
                                    }}
                                  />
                                </>
                              )}
                              {(loggedInData?.role == "SUPER_ADMIN" ||
                                loggedInData?.role == "MANAGER") && (
                                <img
                                  title="Merge"
                                  src="./images/merge_icon.svg"
                                  onClick={() => {
                                    setMergePopUp(true);
                                    setAssessmentDetails(assessment);
                                  }}
                                />
                              )}

                              {loggedInData?.role == "VIEWER" && (
                                <img
                                  src="/images/price-tag.png"
                                  width="18"
                                  onClick={() =>
                                    manageTagModal(assessment["id"])
                                  }
                                />
                              )}
                            </td>
                            {loggedInData?.role == "SUPER_ADMIN" &&
                              accountNameList.map((item, idx) => {
                                return (
                                  <td
                                    className="user_role_text ellipsis"
                                    key={index + "_" + idx}
                                  >
                                    {assessment[item] == 0
                                      ? "-"
                                      : assessment[item]}
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(params.pageNumber)}
                    itemsCountPerPage={limits}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}
              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-6">
                <button className="btn btn-primary">
                  
                    <CSVLink data={assessmentList} target="_blank" >Export user data as a CSV file</CSVLink>
                  
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {mergePopUp && (
        <AssessmentMerge
          show={mergePopUp}
          onHide={() => setMergePopUp(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}
      {copyToCongressModal && (
        <CopyToCongressModal
          show={copyToCongressModal}
          onHide={() => setCopyToCongressModal(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {commentPopUp && (
        <AssessmentEdit
          show={commentPopUp}
          onHide={() => setCommentPopUp(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {commentModal && (
        <Comments
          show={commentModal}
          onHide={() => {
            setCommentModal(false);
          }}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}
      {showTagModal && (
        <ManageRecommendedTag
          recommendedFor={recommendedFor}
          show={showTagModal}
          onHide={() => {
            setShowTagModal(false);
          }}
        />
      )}

      {addNewExpert && (
        <AddNewExpertModal
          show={addNewExpert}
          onHide={() => setAddNewExpert(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}

      {editNewExpert && (
        <EditNewExpertModal
          show={editNewExpert}
          onHide={() => setEditNewExpert(false)}
          assessment={assessmentDetails}
          fetchAssessmentDetail={fetchAssessmentDetail}
          params={params}
          loggedInData={loggedInData}
        />
      )}
    </>
  );
}

export default Assessment;
