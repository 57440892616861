import React, { useEffect, useState } from "react";
import "../TailwindExpert.css";
import ExpertCropImage from "../FormElements/ExpertCropImage";
import {
  deleteExpertProduct,
  getExpertProductList,
  postExpertProduct,
  updateExpertProduct,
} from "../../../Components/action/becomeSpeakerAction";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import DeleteUserModal from "./DeleteUserModal";

const ListProduct = () => {
  const [products, setProducts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({
    product_title: "",
    price: "",
    description_text: "",
    productLink: "",
    imagePath: "",
    currency_icon: "",
  });

  const { loggedInData } = useSelector((state) => state.admin);

  const [isDeletable, setIsDeletable] = useState(false);
  const [deletetableData, setDeletableData] = useState(null);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({});

  const fetchExpertsProductList = async () => {
    setLoading(true);

    getExpertProductList()
      .then((res) => {
        const data = res?.data?.responseData?.data || [];
        setProducts(data);

        if (searchTerm.trim() !== "") {
          const filtered = data.filter((product) =>
            product.product_title
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
          setFilteredProducts(filtered);
        } else {
          setFilteredProducts(data);
        }
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  useEffect(() => {
    fetchExpertsProductList();
  }, []);

  const addUpdateProductPicture = (croppedImage) => {
    console.log(croppedImage, "cc");

    setCurrentProduct((prev) => ({
      ...prev,
      imagePath: croppedImage,
    }));
  };

  const openAddForm = () => {
    setCurrentProduct({
      product_title: "",
      price: "",
      description_text: "",
      productLink: "",
      imagePath: "",
      currency_icon: "1",
    });
    setIsAdding(true);
    setIsEditing(false);
  };

  const openEditForm = (product) => {
    setCurrentProduct(product);
    setIsEditing(true);
    setIsAdding(false);
  };

  const validateForm = () => {
    let errorList = {};
    let isValid = true;

    if (!currentProduct?.product_title) {
      isValid = false;
      errorList.product_title = "Product title is required";
    }
    if (!currentProduct?.productLink) {
      isValid = false;
      errorList.productLink = "Product link is required";
    }
    setErrors(errorList);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!currentProduct.product_title || !currentProduct.price) {
    //   return; // Basic validation
    // }

    if (isEditing) {
      const updatedProducts = products.map((product) =>
        product.id === currentProduct.id ? currentProduct : product
      );
      // console.log(updatedProducts,currentProduct,'cccuuu');
      if (validateForm()) {
        let dataToSend = {
          id: currentProduct?.id,
          productTitle: currentProduct?.product_title,
          descriptionText: currentProduct?.description_text,
          price: currentProduct?.price,
          currencyIcon: currentProduct?.currency_icon,
          imagePath: currentProduct?.imagePath,
          productLink: currentProduct?.productLink,
        };
        await updateExpertProduct(dataToSend);
        toast.success("Product updated successfully");
        setProducts(updatedProducts);
        setIsAdding(false);
        setIsEditing(false);
        fetchExpertsProductList();
        setErrors({});
      }
    } else if (isAdding) {
      if (validateForm()) {
        let dataToSend = {
          userId: loggedInData?.id,
          productTitle: currentProduct?.product_title,
          descriptionText: currentProduct?.description_text,
          price: currentProduct?.price,
          currencyIcon: currentProduct?.currency_icon,
          imagePath: currentProduct?.imagePath,
          productLink: currentProduct?.productLink,
        };
        await postExpertProduct(dataToSend);
        toast.success("New product added successfully");
        setProducts([...products, currentProduct]);
        setIsAdding(false);
        setIsEditing(false);
        fetchExpertsProductList();
        setErrors({});
      }
    }

    // setCurrentProduct({
    //   product_title: "",
    //   price: "",
    //   description_text: "",
    //   productLink: "",
    //   imagePath: "",
    //   currency_icon: "",
    // });
    // setIsAdding(false);
    // setIsEditing(false);
  };

  const handleCancel = () => {
    setCurrentProduct({
      product_title: "",
      price: "",
      description_text: "",
      productLink: "",
      imagePath: "",
      currency_icon: "",
    });
    setIsAdding(false);
    setIsEditing(false);
    // fetchExpertsProductList();
    setErrors({});
  };

  const handleRemoveImage = () => {
    setCurrentProduct((prev) => ({
      ...prev,
      imagePath: "",
    }));
  };

  const handleDeleteProduct = async (product) => {
    await deleteExpertProduct({ id: product?.id });
    fetchExpertsProductList();
  };

  const handleDelete = async (id) => {
    try {
      console.log("Attempting to delete speaker with id:", id);
      await deleteExpertProduct({ id });
      setIsDeletable(false);
      toast.success("Product deleted successfully!");
      fetchExpertsProductList();
    } catch (error) {
      console.error("Error deleting speaker:", error);
      setIsDeletable(true);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeletable(false);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);

    if (term.trim() !== "") {
      const filtered = products.filter((product) =>
        product.product_title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  const uniqueErrors = [...new Set(Object.values(errors))];

  return (
    <article className="mt-10">
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="flex justify-between items-center px-10">
            <span className="font-bold text-2xl">
              {isAdding
                ? "Add Product"
                : isEditing
                ? "Edit Product"
                : "Product Listing"}
            </span>
            <div className="flex gap-5">
              {!isAdding && !isEditing && (
                <div className="flex gap-7">
                  <input
                    style={{ padding: "3px 0px 5px 5px" }}
                    type="text"
                    className="form-control w-[200px]"
                    placeholder="Type Product Title..."
                    value={searchTerm}
                    onChange={(e) => handleSearch(e?.target?.value)}
                  />
                  <button
                    onClick={openAddForm}
                    className="bg-btnColor hover:scale-105 border-none text-white rounded-lg px-5 py-3 font-semibold"
                  >
                    Add Product
                  </button>
                </div>
              )}
              {(isAdding || isEditing) && (
                <button
                  onClick={handleCancel}
                  className="bg-btnColor hover:scale-105 border-none text-white rounded-lg px-5 py-3 font-semibold"
                >
                  Product Listing
                </button>
              )}
            </div>
          </div>

          {isAdding || isEditing ? (
            <div className="mt-8 p-6 bg-white shadow-lg rounded-lg">
              <h3 className="text-2xl font-semibold mb-6">
                {isAdding ? "Add Product" : "Edit Product"}
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4 px-5">
                  <div className="flex flex-col w-full ">
                    <label htmlFor="product_title" className="font-semibold">
                      Product Title
                    </label>
                    <input
                      type="text"
                      id="product_title"
                      name="product_title"
                      value={currentProduct.product_title}
                      onChange={(e) =>
                        setCurrentProduct({
                          ...currentProduct,
                          product_title: e.target.value,
                        })
                      }
                      className="form-control  p-2 border rounded-lg"
                    />

                    {errors?.product_title && (
                      <div className="text-red-500 text-[12px] mt-1 error_refUserName">
                        {errors?.product_title}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    {/* Price Input */}
                    <label htmlFor="price" className="font-semibold">
                      Price
                    </label>
                    <div className="flex gap-3">
                      <select
                        // id="currency_icon"
                        name="currency_icon"
                        value={currentProduct.currency_icon}
                        onChange={(e) =>
                          setCurrentProduct({
                            ...currentProduct,
                            currency_icon: e.target.value,
                          })
                        }
                        className="form-control max-w-[100px]  p-2 border rounded-lg"
                      >
                        <option value="1">€</option>
                        <option value="2">$</option>
                        <option value="3">£</option>
                        <option value="4">¥</option>
                        <option value="5">Fr</option>
                      </select>

                      <input
                        type="text"
                        id="price"
                        name="price"
                        value={currentProduct.price}
                        onChange={(e) =>
                          setCurrentProduct({
                            ...currentProduct,
                            price: e.target.value,
                          })
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        className="form-control  p-2 border rounded-lg mb-4"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col w-full min-h-[300px]">
                    <label htmlFor="description" className="font-semibold">
                      Description
                    </label>
                    <CKEditor
                      className="h-full"
                      editor={ClassicEditor}
                      data={currentProduct.description_text || ""}
                      onReady={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setCurrentProduct((prev) => ({
                          ...prev,
                          description_text: data,
                        }));
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                      disabled={false}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <label htmlFor="productLink" className="font-semibold">
                      Product Link
                    </label>
                    <input
                      type="text"
                      id="productLink"
                      name="productLink"
                      value={currentProduct.productLink}
                      onChange={(e) =>
                        setCurrentProduct({
                          ...currentProduct,
                          productLink: e.target.value,
                        })
                      }
                      className="form-control  p-2 border rounded-lg"
                    />

                    {errors?.productLink && (
                      <div className="text-red-500 text-[12px] mt-1 error_refUserName">
                        {errors?.productLink}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block text-xl font-bold mb-2">
                      Product Image:
                    </label>
                    {currentProduct.imagePath ? (
                      <div className="mb-2 relative w-[148px] h-[148px]">
                        <img
                          src={currentProduct.imagePath}
                          alt="Cropped Preview"
                          className="w-full h-full object-cover rounded border border-gray-300"
                        />
                        <img
                          className="absolute top-1 right-1 w-10 h-10 p-2 bg-white rounded-full hover:cursor-pointer hover:scale-105"
                          title="Delete"
                          src="./images/delete.png"
                          alt="Delete Icon"
                          onClick={handleRemoveImage}
                        />
                      </div>
                    ) : (
                      <ExpertCropImage
                        addUpdateProductPicture={addUpdateProductPicture}
                      />
                    )}
                  </div>

                  <div className="mt-6 flex gap-4">
                    <button
                      type="submit"
                      className="bg-btnColor text-white px-5 py-3 rounded-lg border-none font-semibold"
                    >
                      {isEditing ? "Save Changes" : "Add Product"}
                    </button>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-gray-300 text-black px-5 py-3 border-none rounded-lg font-semibold"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => {
                  const currency = { 1: "€", 2: "$", 3: "£", 4: "¥", 5: "Fr" }[
                    product?.currency_icon
                  ];
                  return (
                    <div
                      key={product.id}
                      className="bg-white shadow-lg rounded-lg flex flex-col"
                    >
                      <div className="flex items-center p-4">
                        <img
                          src={product.imagePath}
                          alt={product.product_title}
                          className="w-[100px] h-[100px] object-cover rounded-lg mr-4"
                        />
                        <div className="flex flex-col justify-between flex-grow">
                          <div>
                            <h3 className="text-2xl font-semibold">
                              {product.product_title}
                            </h3>
                            {/* <p
                              className="text-gray-500 text-xl"
                              dangerouslySetInnerHTML={{
                                __html:
                                  product.description_text ||
                                  "No description available",
                              }}
                            ></p> */}
                          </div>
                          <div className="flex items-center justify-between mt-4">
                            <span className="text-xl font-bold">
                              {(product.price && (
                                <span className="">{currency}</span>
                              )) ||
                                ""}{" "}
                              {product.price || "N/A"}
                            </span>
                            <div className="flex items-center gap-4">
                              <img
                                className="actionImg hover:cursor-pointer"
                                title="Edit"
                                src="./images/edit.png"
                                onClick={() => openEditForm(product)}
                              />
                              <img
                                className="actionImg hover:cursor-pointer"
                                title="Delete"
                                src="./images/delete.png"
                                onClick={() => {
                                  setDeletableData(product);
                                  setIsDeletable(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-span-full text-center text-2xl text-red-500">
                  No products found
                </div>
              )}
            </div>
          )}
        </>
      )}

      {/* {(isAdding || isEditing) && uniqueErrors.length > 0 && (
        <div className="text-red-500 text-[12px]">
          {uniqueErrors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )} */}

      {isDeletable && (
        <DeleteUserModal
          deletetableData={deletetableData}
          handleDelete={handleDelete}
          handleDeleteCancel={handleDeleteCancel}
        />
      )}
    </article>
  );
};

export default ListProduct;
