import React from "react";
import SideBar from "../Dashboard/SideBar";
import ExpertForm from "./ExpertForm";

const Experts = () => {
  return (
    <div className="">
      <SideBar />
      <div className="content-page">
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="w-full max-h-[95vh] overflow-auto">
            <div
              className="bg-cover bg-center w-full bg-no-repeat h-[200px] flex items-center pb-32"
              style={{ backgroundImage: "url(/images/expert/bg-expert.png)" }}
            >
              <span className="text-blue-400 mr-2">Online Congress </span> /
              Expert
            </div>
            <ExpertForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
