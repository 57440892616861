export const BASE_URL = 'https://api.elitespeaker.de/';
export const KONGRESS_ATUH_KEY = "KPbHAq2rH3DFMoIOF2dCQFZV3THjHbdVNK";
export const COPY_KONGRESS_END_POINT = "/create-expert";
//export const BASE_URL = 'http://54.93.107.37:3010/';
//export const BASE_URL = 'http://54.176.169.179:3017/';
// export const BASE_URL = 'http://54.93.107.37:3010/';



export const USER_ROLE = [
  { label: 'Super admin', value: 'SUPER_ADMIN' },
  { label: 'Manager', value: 'MANAGER' },
  { label: 'Viewer', value: 'VIEWER' },
]


export const CONFIG = {
  deviceType: 'WEB',
  countryCode: '974',
  contentLanguage: 'en',
  utcOffset: -1 * new Date().getTimezoneOffset(),
};


export const EXPERT_ASSESSMENT_REQUEST_STATUS= {
  PENDING: 0,
  ACCEPT: 1,
  REJECT: 2
}